<template>
  <se-auth-login-page/>
</template>

<script lang="ts" setup>
import SeAuthLoginPage from "@/src/modules/auth/views/login.vue";
</script>

<script lang="ts">
import {theme} from '@/theme';

export default {
  meta() {
    return theme.auth.login.seo
  },
}
</script>
