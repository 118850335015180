<template>
  <se-auth-form-wrapper>
    <div class="text-black text-center text-h-xl font-bold pb-24">
      Welcome Back!
    </div>
    
    <OAuthSignIn redirect="/home" />

    <p class="line-on-side text-neutral-60 text-center text-b-xs mx-2 !mt-32 !mb-24">
      <span>or sign in with email</span>
    </p>

    <se-auth-login-form redirect="/home" />

    <div class="text-center text-b-s font-bold mt-3">
      Don't have an account?
      <Link
        class="!text-primary-100 xs:hover:text-primary-100 no-underline xs:hover:underline"
        :href="{ path: '/register' }"
      >
        Sign up
      </Link>
    </div>
  </se-auth-form-wrapper>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import Link from '@/src/components/Link';
import OAuthSignIn from '@/src/components/OAuthSignIn';
import SeAuthLoginForm from '../components/login-form';
import SeAuthFormWrapper from '../components/shared/auth-form-wrapper';

const { $router, $auth } = getGlobalProperties();

onMounted(() => {
  if ($auth?.check()) {
    $router.push('/home');
    return false;
  }
});
</script>

<script lang="ts">
export default {
  name: 'SeAuthLoginPage',
};
</script>
